<template>
  <md-card class="md-card-profile" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header>
      <div class="md-card-avatar">
        <img class="img" src="@/assets/img/avatar.jpg" />
      </div>
    </md-card-header>
    <md-card-content>
      <div id="firebaseui-auth-container"></div>
    </md-card-content>
  </md-card>
</template>

<script>
import { firebase } from "@/firebase";
import * as firebaseui from "firebaseui";
export default {
  name: "lock-card",
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  mounted() {
    const ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          authResult.user.getIdTokenResult().then((tokenResult) => {
            if (tokenResult.claims.allow_access) {
              this.$store.commit("setUser", {
                uid: authResult.user.uid,
                name: authResult.user.displayName,
              });
              this.$router.push({ name: "home" });
            } else {
              this.$router.push({ name: "noaccess" });
            }
          });
          return false;
        },
      },
      signInFlow: "popup",
      signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },
  methods: {
    showCard: function() {
      this.cardHidden = false;
    },
  },
};
</script>

<style lang="css">
.firebaseui-idp-google,
.firebaseui-idp-google:hover,
.mdl-button.firebaseui-idp-google:active,
.mdl-button.firebaseui-idp-google:focus {
  background-color: #212529 !important;
  height: 48px;
  cursor: pointer;
}

.firebaseui-idp-google > .firebaseui-idp-text {
  color: #fafafa !important;
}
.firebaseui-card-content {
  padding: 0 24px;
}
.firebaseui-idp-icon-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.firebaseui-idp-icon {
  width: 25px !important;
  vertical-align: middle;
}
.firebaseui-idp-list {
  padding-inline-start: 0px;
  width: 152px;
  height: 48px;
}
.firebaseui-idp-text.firebaseui-idp-text-short {
  display: none;
}
.firebaseui-idp-text.firebaseui-idp-text-long {
  display: table-cell;
}
.firebaseui-idp-text.firebaseui-idp-text-long {
  visibility: hidden;
  position: relative;
}
.firebaseui-idp-text.firebaseui-idp-text-long:after {
  font-size: 14px;
  text-transform: none;
  vertical-align: middle;
  font-weight: 500;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  visibility: visible;
  position: absolute;
  top: 0px;
  left: 4px;
  content: "Iniciar sesión con Google";
}
</style>
